@media (prefers-color-scheme: dark) {
  body[appearance="default"] {
    --text: white;
    --text-contrast: white;
    --highlight: #004953;
    --background: #171717;
    --placeholder: #bdc1c6;
    --font-main: "Roboto";
    --font-title: "Open Sans";
    --secondary: #ffffff1a;
    --hover: #ffffff33;
  }
}

@media (prefers-color-scheme: light) {
  body[appearance="default"] {
    --text: black;
    --text-contrast: white;
    --highlight: #004953;
    --background: #d8dee9;
    --placeholder: lightgray;
    --font-main: "Roboto";
    --font-title: "Open Sans";
    --secondary: #0000001a;
    --hover: #00000033;
  }
}

body[appearance="dark"] {
  --text: white;
  --text-contrast: white;
  --highlight: #004953;
  --background: #171717;
  --placeholder: #bdc1c6;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="light"] {
  --text: black;
  --text-contrast: white;
  --highlight: #004953;
  --background: #d8dee9;
  --placeholder: lightgray;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #0000001a;
  --hover: #00000033;
}

body[appearance="lime"] {
  --text: white;
  --text-contrast: black;
  --highlight: lime;
  --background: #181c19;
  --placeholder: black;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="space"] {
  --text: white;
  --text-contrast: white;
  --highlight: #7f00ff;
  --background: black;
  --placeholder: white;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="molten"] {
  --text: white;
  --text-contrast: black;
  --highlight: #ff6868;
  --background: black;
  --placeholder: black;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="retro"] {
  --text: white;
  --text-contrast: black;
  --highlight: #009600;
  --background: black;
  --placeholder: black;
  --font-main: "Ubuntu Mono";
  --font-title: "Ubuntu Mono";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="eaglenet"] {
  --text: white;
  --text-contrast: white;
  --highlight: #4d004d;
  --background: linear-gradient(to right, #665fd2, #9824d3);
  --placeholder: white;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="midnight"] {
  --text: white;
  --text-contrast: white;
  --highlight: #4b0082;
  --background: #150025;
  --placeholder: lightgray;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="honey"] {
  --text: white;
  --text-contrast: white;
  --highlight: #e66102;
  --background: #ffa15f;
  --placeholder: lightgrey;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #0000001a;
  --hover: #00000033;
}

body[appearance="swamp"] {
  --text: white;
  --text-contrast: black;
  --highlight: #15fc95;
  --background: #006072;
  --placeholder: darkslategray;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="banana"] {
  --text: white;
  --text-contrast: #161616;
  --highlight: #ffb703;
  --background: #161616;
  --placeholder: #161616;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="banana"] .homelogo {
  display: none;
}

body[appearance="banana"] .bananalogo {
  display: initial;
}

body[appearance="flamingo"] {
  --text: white;
  --text-contrast: black;
  --highlight: white;
  --background: #fcacd0;
  --placeholder: #bdc1c6;
  --font-main: "Roboto Slab";
  --font-title: "Prata";
  --secondary: #0000001a;
  --hover: #00000033;
}

body[appearance="magma"] {
  --text: white;
  --text-contrast: white;
  --highlight: #ff001f;
  --background: linear-gradient(to bottom, #10001b, #1d0003);
  --placeholder: white;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="shadow"] {
  --text: white;
  --text-contrast: black;
  --highlight: white;
  --background: black;
  --placeholder: black;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="hacker"] {
  --text: #7de38d;
  --text-contrast: #7de38d;
  --highlight: #232323;
  --background: #090909;
  --placeholder: #7de38d;
  --font-main: "JetBrains Mono";
  --font-title: "JetBrains Mono";
  --secondary: transparent
  --hover: transparent;
}

body[appearance="nord"] {
  --text: #eceff4;
  --text-contrast: #eceff4;
  --highlight: #3b4252;
  --background: #242933;
  --placeholder: #eceff4;
  --font-main: "Rubik";
  --font-title: "Rubik";
  --secondary: transparent;
  --hover: transparent;
}

body[appearance="violet"] {
    --text: white;
    --text-contrast: white;
    --highlight: black;
    --background: #592f7f;
    --placeholder: white;
    --font-main: "Roboto";
    --font-title: "Open Sans";
    --secondary: #ffffff1a;
    --hover: #ffffff33;
}

body[appearance="jungle"] {
    --text: white;
    --text-contrast: black;
    --highlight: #38b000;
    --background: #006400;
    --placeholder: black;
    --font-main: "Roboto";
    --font-title: "Open Sans";
    --secondary: #ffffff1a;
    --hover: #ffffff33;
}

body[appearance="connection"] {
    --text: #43b581;
    --text-contrast: #1c1b29;
    --highlight: #43b581;
    --background: #1c1b29;
    --placeholder: #1c1b29;
    --font-main: "Roboto";
    --font-title: "Open Sans";
    --secondary: #ffffff1a;
    --hover: #ffffff33;
}

body[appearance="dune"] {
  --text: #282425;
  --text-contrast: var(--background);
  --highlight: #7d7259;
  --background: #d2c59d;
  --placeholder: var(--background);
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #0000001a;
  --hover: #00000033;
}

body[appearance="dracula"] {
  --text: #50fa7b;
  --text-contrast: #50fa7b;
  --highlight: #383a59;
  --background: #282a36;
  --placeholder: #50fa7b;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="simple"] {
  --text: white;
  --text-contrast: white;
  --highlight: #292b2f;
  --background: #18191c;
  --placeholder: white;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: transparent;
  --hover: transparent;
}

body[appearance="fracital"] {
  --text: #f971e4;
  --text-contrast: #f971e4;
  --highlight: #333;
  --background: #222;
  --placeholder: #f971e4;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: transparent;
  --hover: transparent;
}

body[appearance="nebula"] {
  --text: #e0def4;
  --text-contrast: var(--background);
  --highlight: #7967dd;
  --background: #191724;
  --nav: #26233a;
  --placeholder: var(--highlight);
  --font-main: "Roboto";
  --font-title: "Roboto";
  --secondary: transparent;
  --hover: transparent;
}

body[appearance="nebula"] .optionchoose {
  background: transparent;
  color: var(--text);
}

body[appearance="nebula"] .chooseactive {
  border: 1px solid var(--highlight);
}

body[appearance="nebula"] .omnibox {
  background: transparent;
  border: 1px solid var(--highlight);
}

body[appearance="nebula"] .search {
  color: var(--text);
}

body[appearance="nebula"] .navitem {
  background: transparent;
  color: var(--highlight);
  border-radius: 0;
}

body[appearance="nebula"] .settingssidecard {
  background: transparent;
  color: var(--text);
}

body[appearance="nebula"] .settingssidecardsel {
  border: 1px solid var(--highlight);
  border-left: none;
}

body[appearance="nebula"] .optionchoosecircle {
  background: transparent;
  border: 1px solid var(--highlight);
  color: var(--highlight);
}

body[appearance="nebula"] .optionchooseinput {
  background: transparent;
  border: 1px solid var(--highlight);
  color: var(--text);
}

body[appearance="nebula"] .suggestions {
  background: transparent;
  border: 1px solid var(--highlight);
  border-top: 0;
}

body[appearance="nebula"] .sugg {
  color: var(--text);
}

body[appearance="tsunami"] {
  --text: white;
  --text-contrast: black;
  --highlight: #2493ff;
  --background: #121212;
  --placeholder: black;
  --font-main: "Roboto";
  --font-title: "Quicksand";
  --secondary: transparent;
  --hover: transparent;
}

body[appearance="tsunami"] .homelogo {
  display: none;
}

body[appearance="tsunami"] .tsunamilogo {
  display: initial;
}

body[appearance="hub"] {
  --text: white;
  --text-contrast: black;
  --highlight: #ff9000;
  --background: black;
  --placeholder: black;
  --font-main: Arial, Helvetica, sans-serif;
  --font-title: Arial, Helvetica, sans-serif;
  --secondary: #ffffff1a;
  --hover: #ffffff33;
}

body[appearance="hub"] .homenav {
  justify-content: flex-start;
}

body[appearance="tiktok"] {
  --text: #fff;
  --text-contrast: #fff;
  --highlight: #ff3b5c;
  --background: #121212;
  --placeholder: #bdc1c6;
  --font-main: "Roboto";
  --font-title: "Open Sans";
  --secondary: #ffffff1f;
  --hover: #ffffff33;
}